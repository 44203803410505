<template>
  <div>
    <div class="search">
      <div>
        <div class="a-inline-block">
          <label for="">关键字</label>
          <a-input v-model="key" style="width:350px" size="large" placeholder="项目名称、项目编号、客户姓名、客户电话" />
        </div>
        <div class="a-inline-block">
          <label for="">时间</label>
          <a-month-picker @change="pickMonth" size="large" placeholder="请选择月份" />
        </div>
        <div class="a-inline-block">
          <label for="">负责人</label>
          <a-input size="large" @focus="focus" :value="onStaff.name||''" @click="staffFlag=true" placeholder="请选择负责人" />

          <!-- <a-select v-model="selStaff" class="ant-select-lg">
                         <a-select-option :value="item.userid" v-for="(item,index) in staff" :key="index" >{{item.name}}</a-select-option>
                    </a-select> -->
        </div>
        <div class="a-inline-block">
          <label for="">状态</label>
          <a-select v-model="selStatus" class="ant-select-lg">
            <a-select-option :value="1">进行中</a-select-option>
            <a-select-option :value="2">已完结</a-select-option>
          </a-select>
        </div>
        <div class="a-inline-block">
          <a-button size="large" type="primary" @click="onSearch">搜索</a-button>
          <a-button size="large" @click="reset">重置</a-button>
        </div>
      </div>
    </div>
    <div style="text-align:right;">
      <div class="a-inline-block" @click="annexModel=true">
        <img src="@/assets/imgs/tMenu4.png" alt="" /><label for="">导入</label>
      </div>
      <div class="a-inline-block" @click="excelout">
        <img src="@/assets/imgs/tMenu4.png" alt=""><label for="">导出</label>
      </div>
      <a-button class="addBtn" size="large" type="primary" @click="delAll">批量删除</a-button>
      <!-- <a-button class="addBtn" size="large" type="primary" @click="addItem">添加项目</a-button> -->
    </div>
    <a-table :row-selection="rowSelection" :rowKey="record =>record.id" :columns="columns" :data-source="data" class="table"
      :pagination="pagination">
      <span slot="num" slot-scope="text, record, index">
        {{(pagination.current-1)*pagination.pageSize+parseInt(index)+1}}
      </span>
      <template slot="ItemName" slot-scope="record">
        <a href="javascript:;" @click="showDetail(record.id)">{{record.pname}}</a>
      </template>
      <template slot="Status" slot-scope="record">
        <span v-if="record.pstatus==2">
          已完结
        </span>
        <a-popconfirm v-else title="确定要改为已完成吗?" @confirm="() => setStatus(record.id)">
          <span style="color:#0abf53;cursor: pointer;"> 进行中 </span>
        </a-popconfirm>
      </template>
      <template slot="Action" slot-scope="text,record">
        <a class="action" href="javascript:;" @click="editTask(record.id)">编辑</a>
        <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(record.id)">
          <a class="action del" href="javascript:;">删除</a>
        </a-popconfirm>
        <a class="action add" href="javascript:;" @click="showLabor(record.id)">添加工时</a>
      </template>
    </a-table>
    <staff :staffFlag="staffFlag" @updateStaffFlag="updateStaffFlag"></staff>

    <a-modal v-model="annexModel" :centered="true" :footer="null" title="导入">
      <div>
        请先下载模板，按格式修改后导入
        <a-button @click="downExl()">下载模板</a-button>
      </div>
      <div>
        <label for="annex" class="annexBtn">
          <img src="@/assets/imgs/ico_daoru.png" alt="" />
          <a-button @click="$refs.annexFile.click()">导入</a-button>
        </label>
        <input type="file" id="annex" style="display: none" ref="annexFile" @change="annexUpload($event)" />
      </div>
    </a-modal>
    <a-modal v-model="showModal" title="添加工时" @ok="setLabor()">
      <div class="addBox" style="padding-bottom:50px">
        <div class="add_title">请选择工人</div>
        <a-select v-model="onTemp" mode="multiple" class="ant-select-lg" style="width: 350px;">
          <a-select-option :value="item.id" v-for="(item,index) in tempList" :key="index">{{item.name}}
          </a-select-option>
        </a-select>
        <div class="add_title">请输入出勤时长（天）</div>
        <a-input class="add_inp ant-input-lg" type="number" style="width: 350px;" v-model="laborTime" step="0.1" />
        <div class="add_title">请输入工作日期</div>
        <a-date-picker @change="onPickTime" v-model="laborDate" style="width: 350px;" size="large" />
        <div class="add_title">请选择工作内容</div>
        <a-select v-model="onSkill" class="ant-select-lg" style="width: 350px;">
          <a-select-option :value="item.id" v-for="(item,index) in skillList" :key="index">{{item.name}}
          </a-select-option>
        </a-select>
      </div>
    </a-modal>
  </div>

</template>
<script>
import Staff from "../../../components/staff.vue";
import { requestXml, ajaxUrl, fileUpload } from "../../../assets/js/request";
export default {
  components: {
    Staff,
  },
  data() {
    return {
      key: "",
      columns: [
        {
          title: "序号",
          dataIndex: "num",
          width: 70,
          align: "center",
          scopedSlots: { customRender: "num" },
        },
        {
          title: "项目名称",
          width: 180,
          scopedSlots: { customRender: "ItemName" },
          key: "1",
        },
        { title: "合同编号", width: 80, dataIndex: "orderid", key: "2" },
        // { title: '身份证号',width:150, dataIndex: 'code', key: '3'},
        { title: "时间", dataIndex: "createtime", key: "4" },
        { title: "客户名称", width: 80, dataIndex: "cname", key: "5" },
        { title: "客户电话", width: 80, dataIndex: "ctel", key: "6" },
        { title: "项目分类", dataIndex: "project_type", key: "7" },
        // { title: '服务项目',width:100, dataIndex: 'time', key: '9' },
        { title: "地址", width: 180, dataIndex: "address", key: "10" },
        // { title: '银行卡号',width:150, dataIndex: 'card', key: '11' },
        { title: "负责人", dataIndex: "sname", key: "12" },
        { title: "介绍人", dataIndex: "introduce", key: "14" },
        {
          title: "状态",
          align: "center",
          scopedSlots: { customRender: "Status" },
          key: "15",
        },
        { title: "备注", dataIndex: "remarks", key: "17" },
        {
          title: "操作",
          key: "operation",
          align: "center",
          fixed: "right",
          width: 250,
          scopedSlots: { customRender: "Action" },
        },
      ],

      data: [],
      // 分页
      pagination: {
        current: 1,
        total: 100,
        pageSize: 10, // 默认每页显示数量
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        showQuickJumper: true,
        showTotal: total => `共 ${total} 条`, // 显示总数
        onChange: (current) => this.changePage(current), //点击当前页码
        onShowSizeChange: (current, pageSize) => {
          //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
          console.log(pageSize);
          this.showSizeChange(current, pageSize);
        },
      },
      showModal: false, //添加工时弹框
      staffFlag: false,
      onStaff: {
        name: "",
        userid: "",
      },
      onItem: "",
      tempList: [], //工人列表
      onTemp: [],
      skillList: [], //技能列表
      onSkill: undefined,
      laborTime: "", //出勤工时
      laborDate: "", //出勤日期
      staff: "", //员工列表
      selStaff: "",
      selStatus: "",
      selMonth: "",
      search: "", //搜索条件
      annexModel: false, //导入弹框
      selectedRows: [],
      rowSelection: {
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
          this.selectedRows = selectedRows;
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
          this.selectedRows = selectedRows;
        },
      },
    };
  },
    beforeRouteEnter(to, from, next) {
      next(vm=>{
        vm.fromPath = from.path
      })
    },
    beforeDestroy(){
        sessionStorage.setItem('_itemList',JSON.stringify(this._data))
    },
    mounted(){
        if(this.fromPath == "/scrm_pc/temp/itemAdd") {
            var data=JSON.parse(sessionStorage.getItem('_itemList'))
            sessionStorage.removeItem('_itemList')
            for(let key in data){
                console.log(data[key],this[key])
                this[key] = data[key]
            }
        }
    this.getData();
    this.getSkill();
    // this.getStaff();
  },
  methods: {
    // 搜索
    onSearch() {
      this.pagination.current=1;
      this.getData();
    },
    // 负责人-输入框聚焦是清除其焦点
    focus(e) {
      e.srcElement.blur();
    },
    // 分配完
    updateStaffFlag(data) {
      // console.log(data,"dasds")
      if (data != 1) {
        this.onStaff = data;
      }
      this.staffFlag = false;
    },
    reset() {
      this.key = "";
      this.onStaff.userid = "";
      this.onStaff.name = "";
      // this.selStaff= "";
      this.selStatus = "";
      this.selMonth = "";
      this.getData();
    },
    // 下载模板
    downExl() {
      window.open(
        window.location.protocol +
          "//" +
          window.location.host +
          "/uploads/项目信息导入.xls"
      );
    },
    // 导入
    annexUpload(e) {
      fileUpload(e, (res) => {
        console.log(res);
        requestXml(
          "/jieb/Project/projectinput",
          "POST",
          (res) => {
            console.log(res);
            this.annexModel = false;
            this.$refs.annexFile.value = "";
            this.getData();
          },
          { id: res.id }
        );
      });
    },
    // 导出
    excelout() {
      window.open(
        ajaxUrl +
          "/jieb/Project/projectout?search=" +
          JSON.stringify(this.search)
      );
    },
    pickMonth(date, dateString) {
      console.log(date);
      var time = new Date(dateString).getTime();
      this.selMonth = Math.round(time / 1000);
    },
    getStaff() {
      requestXml("/scrm/Staff/getMinList", "POST", (res) => {
        this.staff = res;
      });
    },
    //到详情
    showDetail(id) {
      this.$router.push({
        path: "/scrm_pc/item/itemDetail",
        query: {
          id: id,
        },
      });
    },
    closeM() {
      this.showModal = false;
    },
    // 显示添加工时弹框
    showLabor(id) {
      this.showModal = true;
      this.onItem = id;
      this.getTemp(id);
    },
    onPickTime(date, dateString) {
      console.log(date);
      this.laborDate = dateString;
    },
    setLabor() {
      let temp = this.onTemp;
      let skill = this.onSkill;
      if (temp.length < 1 || skill == "") {
        this.$message.info("请选择工人或者工作内容");
        return false;
      }
      // console.log(this.laborTime,this.laborDate);
      if (this.laborTime == "" || this.laborDate == "") {
        this.$message.info("请填写工时或者工作日期");
        return false;
      }

      requestXml(
        "/jieb/Project/attendanceadd",
        "POST",
        (res) => {
          if (res != "errer") {
            this.onTemp=undefined;
            this.onItem = "";
            this.onSkill = undefined;
            this.laborTime = ""; //出勤工时
            this.laborDate = ""; //出勤日期
            this.showModal = false;
            this.$message.success("添加成功");
          }
        },
        {
          projectid: this.onItem,
          temporaryid: temp.join(","),
          skillid: skill,
          time: this.laborTime,
          date: this.laborDate,
        }
      );
    },
    // 修改项目状态
    setStatus(id) {
      requestXml(
        "/jieb/Project/statusedit",
        "POST",
        (res) => {
          console.log(res);
          this.getData();
        },
        { id: id, status: 2 }
      );
    },
    // 获取技能列表
    getSkill() {
      requestXml(
        "/jieb/Temporary/skillshow",
        "POST",
        (res) => {
          this.skillList = res.list;
        },
        { page: { curpage: 1, pagesize: 9999 } }
      );
    },
    // 获取工人列表
    getTemp(id) {
      requestXml(
        "/jieb/Project/attendanceadd",
        "GET",
        (res) => {
          if(res!='success'){
            this.tempList = res;
          }else{
            // this.$message.info("当前项目未添加工人");
          }
          // this.tempList = res;
          console.log(this.tempList);
        },
        { id: id }
      );
    },
    getData() {
      var onTime = "";
      if (this.selMonth > 0) {
        onTime = this.selMonth - 28800;
      }
      requestXml(
        "/jieb/Project/projectshow",
        "POST",
        (res) => {
          console.log(res);

          for (let i = 0; i < res.list.length; i++) {
            // res.list[i].pstatus =  this.status[Number(res.list[i].pstatus)];
            res.list[i].createtime =
              res.list[i].createtime.split(" ")[0] +
              "至" +
              res.list[i].endtime.split(" ")[0];
          }
          this.pagination.total = Number(res.page.total);
          this.pagination.pageSize = Number(res.page.pagesize);
          this.data = res.list;
          this.search = res.search;
        },
        {
          key: this.key,
          time: onTime,
          responsibility: this.onStaff.userid,
          status: this.selStatus,
          page: {
            curpage: this.pagination.current,
            pagesize: this.pagination.pageSize,
          },
        }
      );
    },
    // 分页
    changePage(current) {
      //current参数表示是点击当前的页码，
      console.log(current);
      this.pagination.current = current;
      this.getData(); //向后端发送请求
    },
    showSizeChange(current, pageSize) {
      console.log(current, pageSize);
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getData(); //向后端发送请求
    },
    addItem() {
      this.$router.push({
        path: "/scrm_pc/temp/itemAdd",
      });
    },
    // 编辑
    editTask(id) {
      this.$router.push({
        path: "/scrm_pc/temp/itemAdd",
        query: {
          id: id,
        },
      });
    },
    // 删除
    onDelete(id) {
      requestXml(
        "/jieb/Project/projectdel",
        "POST",
        (res) => {
          console.log(res);
          this.getData();
          this.selectedRows=[];
        },
        { id: id }
      );
    },
    delAll() {
      if (this.selectedRows.length < 1) {
        this.$message.info("请先选择");
        return false;
      } else {
        let idArr = [];
        let sel = this.selectedRows;
        for (let i = 0; i < sel.length; i++) {
          idArr.push(sel[i].id);
        }
        this.onDelete(idArr);
      }
    },
  },
};
</script>
<style scoped>
.search {
  display: flex;
  justify-content: space-between;
}
.addBtn {
  width: auto;
}
.action {
  display: inline-block;
  padding: 5px 10px;
  margin: 0 5px;
  background: #dceeff;
  border: 1px solid #36a3ff;
  color: #36a3ff;
  border-radius: 5px;
}
.del {
  background: #ffdcdc;
  border-color: #ff4c4c;
  color: #ff4c4c;
}
.add {
  background: #fbeade;
  border-color: #f47721;
  color: #f47721;
}
.add_title {
  margin-bottom: 15px;
  margin-top: 15px;
}
.annexBtn {
  display: block;
  margin: 20px auto;
  width: 200px;
  text-align: center;
}
.annexBtn img {
  display: block;
  width: 100px;
  margin: 10px auto;
}
</style>

